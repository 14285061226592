class Errors {
    // errors:object;
    constructor(){
        this.errors = {}
        this.modal = {
            open: false
        }
    }

    // get(field:string){
    get(field){
        if(this.errors[field]){
            return this.errors[field][0];
        }
    }



    any(){
        return Object.keys(this.errors).length > 0;
    }

    has(field){
    // has(field:string){
        return this.errors.hasOwnProperty(field);
    }

    clear(field){
    // clear(field:string){
        delete this.errors[field];
    }

    // record(errors:object){
    record(errors){
        this.errors = errors;
    }
}

export { Errors };