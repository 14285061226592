import { createApp } from 'vue'

const Site = createApp({
    data(){
        return {
            mobileUI: {
                'open' : false
            }
        }
    },
    computed: {
        allowsScheduling() {
            return window.drivescout_config.shop.info.allows_scheduling === true;
        }
    }
});

export default Site;
