import { createApp } from 'vue'
import axios from 'axios';
import defaultFormData from './defaultFormData'
import { AgeVerify } from "./AgeVerify";
import { ShopRules } from "./ShopRules";
import {Errors} from './Errors';
import { inCents, inDollars, percentageOfXInCents } from './Teller'
import { dateStringToDate, dateTimeStringToDate, getDurationInMinutes, dateToYMD } from './time';
import defaultData from './defaultFormData';
import AuthorizeCard from './AuthorizeCard.vue';
import StripeCard from './StripeCard.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { Product } from './Product';
import { Coupon } from './Coupon';
import { vMaska } from "maska/vue"

window.drivescout_config = window.drivescout_config || {};

const drivescout = createApp({
    components: {
        VueDatePicker: VueDatePicker,
        AuthorizeCard: AuthorizeCard,
        StripeCard: StripeCard
    },
    directives: {
        maska: vMaska
    },
    data() {
        return {
            menu: false,
            datepickerState: {
                date: new Date()
            },
            addToCartMessage: '',
            displayPaymentOptions: false,
            productAddedToCart: {},

            doesNotHaveLearnersPermit: false,

            error_message: null,
            success_message: null,

            errors: new Errors(),

            form: {
                'submitting' : false,
                'busy' : false,
                'submitText' : 'Submit Order',
                'student': {
                    'firstname' : null,
                    'lastname' : null,
                    'email' : null,
                    'phone' : null,
                    'dob' : null,
                    'driving_experience' : null,
                    'access_token' : null
                },
                'customer_notes' : null,
                'guardian' : {
                    'firstname' : null,
                    'lastname' : null,
                    'email' : null,
                    'phone' : null
                },
                'permit': {
                    'permit_number' : null,
                    'issue_date' : null,
                    'expiration_date' : null
                },
                'card' : {
                    'payment_amount' : null,
                    'name_on_card' : null,
                    'number' : null,
                    'expiration_month' : null,
                    'expiration_year' : null,
                    'cvc' : null
                },
                'billing_address': {
                    'street' : null,
                    'city' : null,
                    'state' : null,
                    'zip' : null,
                    'country' : 'US'
                },
                'home_address': {
                    'street' : null,
                    'city' : null,
                    'state' : null,
                    'zip' : null
                },
                'terms_of_service' : null
            },

            loginForm: {
                username: "",
                password: "",
                error: {
                    title: "",
                    detail: ""
                },
                isBusy: false
            },

            cart: {
                'loading' : false,
                'products': [],
                'appointments': [],
                'coupon': null
            },

            stripeElementOptions : {
                currency: 'usd',
                amount: 0,
            },

            schedule : {
                filter: {
                    address: {
                        "street": "",
                        "city" : "",
                        "state" : "",
                        "postal_code": ""
                    },
                    services: {
                        session_types: [],
                        course_types: []
                    },
                    instructors: [],
                    instructor: 0,
                    service: {
                        id: 0,
                        name: 'All',
                        type: 'session_type'
                    },
                    start_date: "",
                    end_date: ""
                },
                appointments: []
            },
            shopRules: new ShopRules(),

            couponForm: {
                submitting: false,
                coupon_code: null,
            },

            cartUrl: window.drivescout_config.cartUrl,
            successUrl: window.drivescout_config.successUrl,
            shopUrl: window.drivescout_config.shopUrl,
            checkoutUrl: window.drivescout_config.checkoutUrl,
            scheduleUrl: window.drivescout_config.scheduleUrl,
            loginUrl: window.drivescout_config.loginUrl,

            states: window.drivescout_config.states,

            studentHasAccount: false,
            surchargePercentage: parseFloat(window.drivescout_config.shop.info.surcharge_percentage) / 100,

            sections: {
                student_information: {
                    visible: true,
                },
                billing_information: {
                    visible: true,
                },
                payment_information: {
                    visible: true,
                },
                terms_of_service: {
                    visible: true
                },
                login: {
                    visible: true
                }
            },
            shop: {
                name: window.drivescout_config.shop.name,
                phone: window.drivescout_config.shop.phone,
                info: window.drivescout_config.shop.info
            },
            urls: window.drivescout_config.urls
        }
    },
    created() {

        let firstDay = new Date(new Date().setDate(new Date().getDate() + 1));
        let lastDay = new Date(new Date().setDate(firstDay.getDate() + 30));
        this.schedule.filter.start_date = dateToYMD(firstDay);
        this.schedule.filter.end_date = dateToYMD(lastDay);

        const rules = new ShopRules(window.drivescout_config.shop.info);
        this.schedule.filter.instructors = window.drivescout_config.shop.info.instructors.map(instructor => instructor);
        this.schedule.filter.instructors.unshift({'id': 0, 'firstname': 'All', 'lastname' : 'All', 'fullname' : 'All'});
        this.schedule.filter.services.session_types = window.drivescout_config.shop.info.services.session_types.map(service => {
            service.type = 'session_type';
            return service;
        });
        this.schedule.filter.services.session_types.unshift({'id' : 0, 'name' : 'All', 'type' : 'session_type'});
        this.schedule.filter.services.course_types = window.drivescout_config.shop.info.services.course_types.map(service => {
            service.type = 'course_type';
            return service;
        });

        let appointmentBars = document.getElementById('scheduling-progress-bars');
        if (appointmentBars) {
            document.getElementsByTagName('body')[0].style.paddingBottom = appointmentBars.offsetHeight + 'px';
        }

        this.rules = rules;
        this.getCart();
        if (window.location.href.indexOf("schedule") > -1) {
            this.submitScheduleFilter();
        }
    },
    watch: {
        doesNotHaveLearnersPermit(newValue, oldValue) {
            this.toggleLearnersPermit(newValue)
        },
        menu (val) {
            this.$refs.menu.$refs.agenda.mode = 'year';
        },
    },
    computed: {
        tokenizationSupported() {
            if (this.rules.gateway() === "authorize" && this.rules.gatewayConfig().client_key) {
                return true;
            }

            if (this.rules.gateway() === "stripe") {
                return true;
            }

            return false;
        },
        allowsDriveScheduling()
        {
            if (this.products().length <= 0){
                return false;
            }

            let allow = this.products().filter(product => {
                return product.allowsDriveScheduling();
            }).length;

            return allow === this.products().length;
        },
        surchargeAmountBilled() {
            if (!this.surchargePercentage) {
                return 0;
            }

            if (this.allowsPayLater && parseInt(this.form.card.payment_amount) > 0) {
                return percentageOfXInCents(parseInt(this.form.card.payment_amount), this.shopRules.surchargePercentage());
            }

            return parseInt((this.surchargePercentage * this.totalDue).toString());
        },
        surchargeAmountBilledInDollars() {
            if (!this.surchargeAmountBilled) {
                return 0;
            }
            return inDollars(this.surchargeAmountBilled);
        },
        surchargeBilledSeparately() {
            return this.shopRules.surchargeBilledSeparately() || false;
        },

        /**
         *
         * @returns {string}
         */
        totalBilled() {

            if (this.allowsPayLater === false) {
                return inDollars(this.applySurchargeTo(this.totalDue) || 0);
            }

            if (this.allowsPayLater && parseInt(this.form.card.payment_amount) > 0) {
                return inDollars(
                    this.applySurchargeTo(
                        this.applyCouponTo(
                            inCents(parseInt(this.form.card.payment_amount))
                        )
                    )
                ) || 0;
            }

            return inDollars(this.applySurchargeTo(this.totalDue)) || 0;
        },
        buttonText() {
            return this.form.submitText + ' for ' + this.totalBilled;
        },
        //


        /**
         *
         * @returns {number}
         */
        orderValueInCents() {
            return this.cart.products.map(p => new Product(p)).reduce((carry, product) => {
                return carry + parseInt(product.price());
            }, 0);
        },


        /**
         * Returns the amount in cents due. Computed as the total product price in cents - applied coupons
         */
        totalDue() {
            if (this.hasCoupon() === false) {
                return this.orderValueInCents;
            }

            let totalDue = this.applyCouponTo(this.orderValueInCents)

            return totalDue;
        },

        /**
         *
         * @returns {boolean}
         */
        allowsPayLater() {
            return this.cart.products.map(p => new Product(p)).filter(product => {
                return product.allowsPayLater();
            }).length > 0;
        },

        /**
         *
         * @returns {boolean}
         */
        requiresDeposit() {
            if (this.allowsPayLater === false) {
                return false;
            }

            return this.products().filter(product => {
                return product.requiresDeposit();
            }).length > 0;
        },

        /**
         *
         * @returns {number}
         */
        minimumDepositAmountRequired() {
            if (this.allowsPayLater === false) {
                return this.totalDue;
            }

            return this.products().reduce(($carry, product) => {
                return product.calculateMinimumDepositRequired(product.price()) + $carry;
            }, 0);
        },

        /**
         *
         * @returns {string}
         */
        subTotalInDollars() {
            const cents = this.products().map(product => {
                return product.price();
            }).reduce((previousValue, currentValue) => {
                return previousValue + currentValue;
            }, 0);

            return inDollars(cents);
        },

        /**
         *
         * @returns {string}
         */
        totalDueInDollars() {
            return inDollars(this.totalDue);
        },




        ///




        surchargeDetail() {
            return window.drivescout_config.shop.info.surcharge_detail || '';
        },
        allowsScheduling() {
            return window.drivescout_config.shop.info.allows_scheduling; // @todo this.shopRules.allowScheduling();
        },
        allowsIndividualClassScheduling() {
            return this.shopRules.allowIndividualClassScheduling();
        },
        hoursProgressBars() {
            // based on their products in the cart, how many of which course types and
            // session types are they purchasing?
            const result = {
                sessionTypes: [],
                courseTypes: []
            };

            const observationHoursExist = this.products()
                                            .map(p => p.sessiontypes()).flat()
                                            .filter(s => {
                                                return s.pivot.observation === true;
                                            }).length > 0;

            if (observationHoursExist) {
                let sessionTypes = this.products().map(product => {
                    return product.sessiontypes();
                }).flat().filter(function (a) {
                    var key = a.id + '|' + a.pivot.observation;
                    if (!this[key]) {
                        this[key] = true;
                        return true;
                    }
                }, Object.create(null));

                sessionTypes.forEach(type => {
                    const minutesOfTypeScheduled = this.appointments().filter(appt => {
                        return 'session' === appt.appointment_type;
                    }).filter(appt => {
                        return type.name === appt.type.name && appt.observation === type.pivot.observation;
                    }).reduce((num, appt) => {
                        return getDurationInMinutes(appt) + num;
                    }, 0);
    
                    let minutesOfTypeInCart = this.products()
                                                    .map(p => p.sessiontypes()).flat()
                                                    .filter(t => {
                                                        return t.id === type.id && type.pivot.observation === t.pivot.observation;
                                                    })
                                                    .reduce((prev, curr) => {
                                                        return prev + curr.pivot.hours;
                                                    }, 0);
                    let name = type.name;
                    if (type.pivot.observation === true) {
                        name += ' (observation)';
                    }
                    result.sessionTypes.push({
                        name: name,
                        minutes_in_schedule: minutesOfTypeScheduled,
                        minutes_in_cart: minutesOfTypeInCart,
                        percentage: (minutesOfTypeScheduled / minutesOfTypeInCart) * 100
                    });
                });
            } else {
                let sessionTypes = this.products().map(product => {
                    return product.sessiontypes();
                }).flat().filter(function (a) {
                    var key = a.id + '|' + a.pivot.observation;
                    if (!this[key]) {
                        this[key] = true;
                        return true;
                    }
                }, Object.create(null)).forEach(type => {
                    const minutesOfTypeScheduled = this.appointments().filter(appt => {
                        return 'session' === appt.appointment_type;
                    }).filter(appt => {
                        return type.name === appt.type.name;
                    }).reduce((num, appt) => {
                        return getDurationInMinutes(appt) + num;
                    }, 0);

                    let minutesOfTypeInCart = this.products()
                                                    .map(p => p.sessiontypes()).flat()
                                                    .filter(t => {
                                                    return t.id === type.id
                                                    })
                                                    .reduce((prev, curr) => {
                                                    return prev + curr.pivot.hours;
                                                    }, 0);
                    result.sessionTypes.push({
                        name: type.name,
                        minutes_in_schedule: minutesOfTypeScheduled,
                        minutes_in_cart: minutesOfTypeInCart,
                        percentage: (minutesOfTypeScheduled / minutesOfTypeInCart) * 100
                    });

                });
            }

            const courseTypes = this.products().map(product => {
                return product.coursetypes();
            }).flat().filter(function (a) {
                var key = a.id;
                if (!this[key]) {
                    this[key] = true;
                    return true;
                }
            }, Object.create(null)).forEach(type => {
                const minutesOfTypeScheduled = this.appointments().filter(appt => {
                    return appt.appointment_type === 'class';
                }).filter(appt => {
                    return type.name === appt.type.name;
                }).reduce((num, appt) => {
                    if (appt.classes) {
                        return appt.classes.reduce((n, a) => {
                            return getDurationInMinutes(a) + n;
                        }, 0);
                    }
                    return getDurationInMinutes(appt) + num;
                }, 0);
                const minutesOfTypeInCart = this.products().filter((product) => {
                    return product.coursetypes().filter((coursetype) => {
                        return coursetype.id === type.id && coursetype.name === type.name;
                    }).length > 0;
                }).map((product) => {
                    return product.coursetypes().map((coursetype) => {
                        return coursetype.pivot.hours;
                    }).reduce((previousValue, currentValue) => {
                        return previousValue + currentValue;
                    });
                }).reduce((previousValue, currentValue) => {
                    return previousValue + currentValue;
                }, 0);

                result.courseTypes.push({
                    name: type.name,
                    minutes_in_schedule: minutesOfTypeScheduled,
                    minutes_in_cart: minutesOfTypeInCart,
                    percentage: (minutesOfTypeScheduled / minutesOfTypeInCart) * 100
                });
            });
            return result;
        }
    },

    methods: {
        async validateForm() {
            let response = true;
            await axios.post(this.shopUrl + '?action=checkout-validate', this.form.data()).then(result => {
                return response;
            }).catch(Error => {
                response = false;
            })
            return response;
        },
        addPlaceholderDataToPermit() {
            this.form.permit.permit_number = 'TBD';
            this.form.permit.issue_date = '01/01/2000';
            this.form.permit.expiration_date = '01/01/2050';
        },
        enableFullPaymentOptions() {
            this.form.card.payment_amount = null;
            this.displayPaymentOptions = true;
            if (this.minimumDepositAmountRequired <= 0) {
                this.proceedToPayment();
            }
        },
        buyNowPayLater() {
            this.form.busy = true;
            this.form.submitting = true;
            let formData = Object.assign({}, this.form);
            delete formData.card;
            delete formData.billing_address;
            this.checkout(formData);
        },
        toggleLearnersPermit(doesNotHavePermit) {
            if (doesNotHavePermit === true) {
                this.addPlaceholderDataToPermit();
            } else {
                this.form.permit.permit_number = '';
                this.form.permit.issue_date = '';
                this.form.permit.expiration_date = '';
            }
        },
        async handleCompletedConfirmationToken(token)
        {
            await this.getCart();

            let formData = Object.assign({}, this.form);
            formData.card = {
                token: token
            }

            this.checkout(formData);
            return true;
        },
        totalBilledInCents()
        {
            if (this.allowsPayLater === false) {
                return this.applySurchargeTo(this.totalDue) || 0;
            }

            if (this.allowsPayLater && parseInt(this.form.card.payment_amount) > 0) {
                return this.applySurchargeTo(
                        this.applyCouponTo(
                            inCents(parseInt(this.form.card.payment_amount))
                        )
                    ) || 0;
            }

            return this.applySurchargeTo(this.totalDue) || 0;
        },
        proceedToPayment(){
            this.stripeElementOptions.amount = this.totalBilledInCents();
        },
        handleAuthorizeCard(tokenData){
            let valid = this.products().length === 0;
            if (!valid) {
                // fail
            }

            let formData = Object.assign({}, this.form);

            if (!this.rules.gatewayConfig().client_key) {
                formData.card = tokenData;
            } else {
                // set token data on order
                formData.card = {
                    token: {
                        opaqueData: tokenData
                    }
                }
                formData.card.save_payment_method = tokenData.save_payment_method;
            }

            this.checkout(formData);
        },
        checkout(data = null) {
            this.success_message = null;
            this.error_message = null;
            if (this.doesNotHaveLearnersPermit === true) {
                this.addPlaceholderDataToPermit();
            }
            if (this.shopRules.cacheCompatibilityEnabled()) {
                this.form.setCacheCompatibilityEnabled(true);
                this.form.setProducts(this.products());
            }

            let formData = {}
            if (data == null) {
                formData = Object.assign({}, this.form);
            } else {
                formData = Object.assign({}, data);
            }

            axios.post(this.shopUrl + '?action=checkout', formData)
                .then(response => {
                    // this.clear();
                    this.form.busy = false;
                    this.form.submitting = false;
                    // this.resetForm();
                    window.localStorage.removeItem('dc-auth-token');
                    window.localStorage.removeItem('dc-user');
                    window.location.replace(this.successUrl);
                })
                .catch(Error => {
                    this.form.busy = false;
                    this.form.submitting = false;
                    this.success_message = null;
                    this.error_message = null;

                    const response = Error.response;

                    if (response.status === 422) {
                        this.errors.record(response.data.data)
                        this.errors.modal.open = true;
                    }

                    for (const property in this.sections) {
                        this.sections[property].visible = true;
                    }

                    if (response.data.hasOwnProperty('message')) {
                        this.errors.record({ error_message : [response.data.message]});
                        this.errors.modal.open = true;
                    }
                    if (response.data.hasOwnProperty('detail')) {
                        this.errors.record({ error_message : [response.data.detail]});
                        this.errors.modal.open = true;
                    }
                });
        },
        setUser(response) {
            const user = response.data.user;
            const token = response.data.access_token;
            this.form.student.access_token = token;
            this.form.student.firstname = user.firstname;
            this.form.student.lastname = user.lastname;
            this.form.student.email = user.email;
            this.form.student.phone = user.phone;
            this.form.student.dob = user.birthday;
            this.form.student.driving_experience = user.driving_experience;
            if (user.permit){
                this.form.permit.permit_number = user.permit.number;
                this.form.permit.issue_date = user.permit.issue_date;
                this.form.permit.expiration_date = user.permit.expiration_date;
            }
            if (user.home_address){
                this.form.home_address.street = user.home_address.street;
                this.form.home_address.city = user.home_address.city;
                this.form.home_address.state = user.home_address.state;
                this.form.home_address.zip = user.home_address.zip;
            }
            window.localStorage.setItem('dc-auth-token', response.data.token);
            window.localStorage.setItem('dc-user', JSON.stringify(response.data.user));
            this.loginForm.busy = false;
        },
        login(){
            this.loginForm.busy = true;
            this.loginForm.error = {
                title: "",
                detail: ""
            }
            axios.post(this.shopUrl + '?action=login', {'username' : this.loginForm.username, 'password' : this.loginForm.password}).then(response => {
                this.setUser(response);
                this.loginForm.username = "";
                this.loginForm.password = "";
            }).catch(Error => {
                this.loginForm.busy = false;
                this.loginForm.error.title = Error.response.data.title;
                this.loginForm.error.detail = Error.response.data.detail;
                if (Error.response.data.message){
                    this.loginForm.error.title = "There was an error logging in.";
                    this.loginForm.error.detail = Error.response.data.message;
                }
                //throw Error;
            });
        },
        studentAge() {
            return AgeVerify(this.form.student.dob);
        },
        addProductToCart(product_id) {
            this.addProduct(product_id);
            this.addToCartMessage = 'Product added to cart successfully.';
        },

        appointmentInCart(appointment){
            return this.appointments().filter(appt => {
                return appt.id == appointment.id;
            }).length > 0;
        },

        /**
         *
         * @param {{type: {name:string}, appointment_type:string}} appointment
         * @returns {number}
         */
        minutesOfTypeScheduled(appointment){
            return this.appointments().filter(appt => {
                return appointment.appointment_type === appt.appointment_type;
            }).filter(appt => {
                return appointment.type.name === appt.type.name && appt.observation === appointment.observation;
            }).reduce((num, appt) => {
                return getDurationInMinutes(appt) + num;
            }, 0);
        },

        /**
         *
         * @param {{type: {name:string}, appointment_type:string}} appointment
         * @returns {number}
         */
        minutesOfTypeInCart(appointment){
            return this.products().filter((product) => {
                if (appointment.appointment_type === 'session') {
                    return product.sessiontypes().filter((sessiontype) => {
                        return sessiontype.id === appointment.type.id && sessiontype.name === appointment.type.name && appointment.observation === sessiontype.pivot.observation;
                    }).length > 0;
                } else {
                    return product.coursetypes().filter((coursetype) => {
                        return coursetype.id === appointment.type.id && coursetype.name === appointment.type.name;
                    }).length > 0;
                }
            }).map((product) => {
                if (appointment.appointment_type === 'session') {
                    return product.sessiontypes().filter((sessiontype) => {
                        return sessiontype.id === appointment.type.id && sessiontype.name === appointment.type.name && appointment.observation === sessiontype.pivot.observation;
                    }).map((sessiontype) => {
                        return sessiontype.pivot.hours;
                    }).reduce((previousValue, currentValue) => {
                        return previousValue + currentValue;
                    }, 0);
                } else {
                    return product.coursetypes().map((coursetype) => {
                        return coursetype.pivot.hours;
                    }).reduce((previousValue, currentValue) => {
                        return previousValue + currentValue;
                    });
                }
            }).reduce((previousValue, currentValue) => {
                return previousValue + currentValue;
            }, 0);
        },

        /**
         *
         * @param {Product} product
         * @param {number} index
         * @returns
         */
        removeProductFromCart(product, index){

            if (this.appointments().length > 0) {
                let sessionTypeHoursRemoved = product.sessiontypes().map((sessiontype) => {
                    return sessiontype.pivot.hours;
                }).reduce((previousValue, currentValue) => {
                    return previousValue + currentValue;
                }, 0);

                let courseTypeHoursRemoved = product.coursetypes().map((coursetype) => {
                    return coursetype.pivot.hours;
                }).reduce((previousValue, currentValue) => {
                    return previousValue + currentValue;
                }, 0);

                if (courseTypeHoursRemoved > 0 || sessionTypeHoursRemoved > 0) {
                    const confirm = window.confirm("Are you sure you want to remove this product from your cart? If you do, it will also remove ALL of the appointments in your schedule.");
                    if (confirm === false){
                        return;
                    }
                }
                this.appointments().forEach((appt, index) => {
                    this.removeAppointment(index);
                });
            }

            this.removeProduct(index);

        },

        /**
         * Apply the configured surcharge to the given amount
         * @param {number} amount 
         * @returns number
         */
        applySurchargeTo(amount){
            return amount + this.calculateSurcharge(amount);
        },

        addAppointmentToSchedule(appointment){

            const AlreadyInCart = this.appointments().filter(appt => {
                return appointment.id == appt.id;
            }).length > 0;
            if (AlreadyInCart === true) {
                this.addToCartMessage = `This appointment is already in your cart.`;
                return;
            }

            let minutesOfTypeScheduled = this.minutesOfTypeScheduled(appointment);
            let minutesOfTypeInCart = this.minutesOfTypeInCart(appointment);
            let potentialMinutesScheduled = minutesOfTypeScheduled + getDurationInMinutes(appointment);

            if (this.shopRules.allowIndividualClassScheduling() === false && appointment.appointment_type === 'class'){
                potentialMinutesScheduled = minutesOfTypeScheduled + (appointment.duration_in_hours * 60);
            }

            let minutesOfTypeRemaining = minutesOfTypeInCart - potentialMinutesScheduled;

            if (minutesOfTypeInCart === 0) {
                this.addToCartMessage = `You must add a product to your cart that has ${appointment.type.name} hours.`;
                return;
            }

            if (minutesOfTypeInCart < potentialMinutesScheduled) {
                this.addToCartMessage = `You do not have enough ${appointment.type.name} hours remaining in your cart to add this appointment to your schedule.`;
                return;
            }

            if (appointment.appointment_type === 'class' && this.allowsIndividualClassScheduling ) {
                // @todo ask if the customer wants to add the entire course to their cart
                const addEntireClass = window.confirm("Would you like to add all of the classes in this course to your cart?");
                if (addEntireClass === true) {
                    const classes = this.schedule.appointments.filter(appt => {
                        return appt.appointment_type === 'class' && appt.course_id === appointment.course_id;
                    }).forEach(appt => {
                        this.cart.addAppointment(appt);
                    });
                    this.addToCartMessage = `Appointment added to cart successfully. You have ${minutesOfTypeRemaining / 60} hours left of ${appointment.type.name} in your cart.`;
                    return;
                }
            }

            const data = Object.assign({}, appointment);
            this.addAppointment(data);
            this.addToCartMessage = `Appointment added to cart successfully. You have ${minutesOfTypeRemaining / 60} hours left of ${appointment.type.name} in your cart.`;
        },
        removeAppointmentFromSchedule(index){
            this.removeAppointment(index);
            this.addToCartMessage = 'Appointment removed from cart successfully.';
        },
        submitScheduleFilter() {

            const query = new URLSearchParams();
            query.append("action", "calendar");

            const startDate = dateStringToDate(this.schedule.filter.start_date);
            const endDate = dateStringToDate(this.schedule.filter.end_date);

            query.append("start", startDate.getFullYear() + '-' + (startDate.getMonth()+1) + '-' + startDate.getDate());
            query.append("end", endDate.getFullYear() + '-' + (endDate.getMonth()+1) + '-' + endDate.getDate());

            if (this.schedule.filter.instructor) {
                query.append("instructor", this.schedule.filter.instructor);
            }

            if (this.schedule.filter.service) {
                query.append("service_type", this.schedule.filter.service.type);
                query.append("service_id", this.schedule.filter.service.id);
            }

            this.schedule.appointments = [];
            // TODO: How many hours are students allowed to schedule at 1 time? this will prevent students from scheduling all of their driving lessons at once from the shop page.
            // TODO: Implement days between session setting
            // TODO: Make min start time of schedule contingent on the setting in the admin portal

            const url = this.shopUrl + "?" + query.toString();

            axios.get(url).then(response => {
                const appointments = response.data.schedule.data.appointments;
                this.schedule.appointments = appointments;
            }).catch(Error => {
                console.error(Error)
                //throw Error;
            });
        },
        dateTimeStringToDate(datetimestring)
        {
            return dateTimeStringToDate(datetimestring);
        },
        YMDtoDate(datetimestring)
        {
            return dateStringToDate(datetimestring);
        },




        ///// form methods

        setBillingAddress(){
            this.form.billing_address.street = this.form.home_address.street;
            this.form.billing_address.city = this.form.home_address.city;
            this.form.billing_address.state = this.form.home_address.state;
            this.form.billing_address.zip = this.form.home_address.zip;
        },

        formIsBusy(){
            return this.form.busy === true || this.form.submitting === true;
        },

        resetForm(){
            this.form = defaultFormData;
        },

        ///// cart methods

        getCart() {
            this.cart.loading = true;
            axios.get(window.drivescout_config.shopUrl + '?action=get-cart').then(response => {
                this.cart = response.data;
                if (!response.data.coupon) {
                    this.cart.coupon = null;
                }
                this.cart.loading = false;
            }).catch(this.onFail);
        },

        /**
         * Adds the product with the given product id to the cart. Makes request.
         * @param {number} productID
         */
        addProduct(productID) {
            this.cart.loading = true;

            axios.get(window.drivescout_config.shopUrl + '?action=add-to-cart&product_id=' + productID)
            .then(response => {
                this.cart.products = response.data.cart.products;
            })
            .catch(this.onFail);
        },

        /**
         * Removes the product with the given index from the cart. Makes request.
         * @param {number} index
         */
        removeProduct(index) {
            this.cart.loading = true;

            axios.get(window.drivescout_config.shopUrl + '?action=remove-from-cart&product_index=' + index)
            .then(response => {
                this.cart.products = response.data.cart.products;
                this.cart.loading = false;
            })
            .catch(this.onFail);
            this.cart.loading = false;
        },

        /**
         * Adds the appointment with the given id to the cart. Makes request.
         * @param {{id:number}} appointment
         */
        addAppointment(appointment) {
            this.loading = true;
            const opts = {
                method: 'post',
                url: window.drivescout_config.shopUrl + '?action=add-to-schedule',
                data: {"appointment" : appointment},
                headers: {
                    "Content-Type" : "application/json; charset=UTF-8",
                    "Accept" : "application/json"
                }
            };
            axios(opts)
                .then(response => {
                    this.cart.appointments = response.data.cart.appointments;
                    this.loading = false;
                })
                .catch(this.onFail)
        },

        /**
         * Remove all appointments. Makes request.
         */
        removeAppointments()
        {
            this.cart.loading = true;
            axios.post(window.drivescout_config.shopUrl + '?action=remove-appointments').then(response => {
                this.cart.appointments = response.data.cart.appointments;
                this.cart.loading = false;
            });
        },

        /**
         * Remove the appointment with the given index from the cart. Makes request.
         * @param {number} index
         */
        removeAppointment(index) {
            this.cart.loading = true;
            axios.get(window.drivescout_config.shopUrl + '?action=remove-from-schedule&appointment_index=' + index)
                .then(response => {
                    this.cart.appointments = response.data.cart.appointments;
                    this.cart.loading = false;
                })
                .catch(this.onFail)
        },

        /**
         * Calculate the surcharge in cents for a given value in cents
         * @param {number} amount
         */
        calculateSurcharge(amount){
            if (this.rules.surchargeBilledSeparately() === false) {
                return 0;
            }

            if (this.rules.surchargeBilledSeparately()) {
                return percentageOfXInCents(amount, this.rules.surchargeAmount());
            }

            return 0;
        },

        /**
         *
         * @param {number} amount
         * @returns {number}
         */
        applyCouponTo(amount) {
            if (this.hasCoupon()) {
                if (this.coupon().type() === 'fixed') {
                    return amount - this.coupon().amount();
                }

                if (this.coupon().type() === 'percentage') {
                    return amount - percentageOfXInCents(amount, this.coupon().amount())
                }
            }
            return amount;
        },

        /**
         * Adds the coupon with the given code to the cart. Makes request.
         * @param {string} coupon_code
         */
        applyCoupon(coupon_code) {
            this.cart.loading = true;
            axios.get(window.drivescout_config.shopUrl + '?action=apply-coupon&coupon=' + coupon_code)
            .then(response => {
                this.cart.coupon = response.data.coupon;
                this.cart.loading = false;
            })
            .then(this.update)
            .catch(Error => {
                window.alert('The provided coupon was invalid.');
                this.cart.loading = false;
                //this.onFail(Error);
            });
        },

        /**
         * Removes the coupon from the cart. Makes request.
         */
        removeCoupon() {
            this.cart.loading = true;
            axios.get(window.drivescout_config.shopUrl + '?action=remove-coupon')
                .then(response => {
                    this.cart.coupon = null;
                    this.cart.loading = false;
                })
                .catch(this.onFail);
        },

        /**
         *
         * @returns {boolean}
         */
        cartIsEmpty() {
            return this.cart.products.length === 0;
        },

        /**
         *
         * @returns {boolean}
         */
        hasCoupon() {
            return this.cart.coupon !== null;
        },

        /**
         *
         * @returns {boolean}
         */
        cartIsLoading() {
            return this.cart.loading === true;
        },

        /**
         * Clears the cart.
         */
        clear() {
            this.cart.loading = true;
            axios.get(window.drivescout_config.shopUrl + '?action=clear-cart').then(response => {
                this.cart = {
                    'loading' : false,
                    'products': [],
                    'appointments' : [],
                    'coupon' : null
                }
            }).catch(this.onFail);
        },

        /**
         *
         * @returns {Product[]}
         */
        products() {
            return this.cart.products.map(p => new Product(p));
        },

        /**
         *
         * @returns {{}[]}
         */
        appointments() {
            return this.cart.appointments;
        },

        coupon() {
            return new Coupon(this.cart.coupon);
        },
    },

});

export default drivescout;

