import '../css/style.css'
import Site from './site.js'
import Checkout from './drivescout/index.js'
import jQuery from 'jquery'

jQuery(() => {
    Checkout.mount('#drivescout-app');
    Site.mount('#drivescout-nav');
})

