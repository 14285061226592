<template>
  <section class="container-fluid">
    <div class="row">
      <div class="col-xs-12">
        <form @submit.prevent="submit()" novalidate>
          <div ref="payment"></div>
          <div class="text-center">
            <button
              class="drivescout-checkout-button"
              data-cy="checkout_button"
              :disabled="buttonDisabled"
              style="margin-top: 2em"
            >
              {{ buttonText }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
  
  <script>
import { loadStripe } from "@stripe/stripe-js";

let stripe;
let elements;

export default {
  props: {
    clientSecret: { type: String, default: "" },
    publishableKey: { type: String, default: "" },
    returnUrl: { type: String, default: window.location.href },
    buttonText: { type: String, default: "Submit" },
    buttonDisabled: { type: Boolean, default: false },
    beforeSubmit: {
      type: Function,
      async default() {
        return {};
      },
    },
    elementOptions: {
      type: Object,
      default: function () {
        return {
          wallets: {
            googlePay: "never",
            applePay: "never",
          },
        };
      },
    },
  },
  data() {
    return {
      confirmParams: {
        return_url: "", // success url
      },
    };
  },
  async created() {
    this.confirmParams.return_url = this.returnUrl;

    this.stripe = await loadStripe(this.publishableKey);

    let options = {
      paymentMethodCreation: "manual",
      mode: "payment",
      ...this.elementOptions,
    };
    options.amount = parseInt(options.amount);
    this.elements = this.stripe.elements(options);
    const paymentElement = this.elements.create("payment");
    paymentElement.mount(this.$refs.payment);
  },
  methods: {
    async submit() {
      const response = await this.beforeSubmit();

      // Trigger form validation and wallet collection
      const { error: submitError } = await this.elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }

      // Create the ConfirmationToken using the details collected by the Payment Element
      // and additional shipping information
      const elements = this.elements;
      const { error, confirmationToken } =
        await this.stripe.createConfirmationToken({
          elements,
          params: {
            return_url: this.returnUrl,
          },
        });

      if (error) {
        // This point is only reached if there's an immediate error when
        // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
        handleError(error);
        return;
      }

      this.tokenCreated(confirmationToken.id);
    },
    tokenCreated(token) {
      this.$emit("stripeCardTokenCreated", token);
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
  },
};
</script>
  