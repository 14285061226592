

// interface CouponData {
//     id:number,
//     code:string,
//     description:string,
//     type:string,
//     amount:number,
//     valid:boolean,
//     start:any,
//     end:any,
//     school_id:number,
//     organisation_id:number,
//     created_at:any,
//     updated_at:any,
//     max_redemptions?:number
// }

class Coupon {
    // constructor(data:CouponData){
    constructor(data){
        this.$originalData = Object.assign({}, data)
        const {id, code, description, type, amount, valid, start, end, school_id, organisation_id, created_at, updated_at, max_redemptions } = data;

        this.$id = id;
        this.$code = code;
        this.$description = description;
        this.$type = type;
        this.$amount = amount;
        this.$valid = valid;
        this.$start = start || null;
        this.$end = end || null;
        this.$school_id = school_id;
        this.$organisation_id = organisation_id;
        this.$created_at = created_at;
        this.$updated_at = updated_at;
        this.$max_redemptions = max_redemptions || null;

    }

    id(){
        return this.$id;
    }

    code(){
        return this.$code;
    }

    description() {
        return this.$description;
    }

    type(){
        return this.$type;
    }

    amount(){
        return this.$amount;
    }

    valid(){
        return this.$valid;
    }

    startDate(){
        return this.$start;
    }

    endDate(){
        return this.$end;
    }

    schoolId(){
        return this.$school_id;
    }

    oragnisationId(){
        return this.$organisation_id;
    }

    createdAt(){
        return this.$created_at;
    }

    updatedAt(){
        return this.$updated_at;
    }

    maxRedemptionsIsSet(){
        return this.maxRedemptions() !==  null && this.maxRedemptions() > 0;
    }

    maxRedemptions() {
        return this.$max_redemptions;
    }

    /**
     * @return {Boolean}
     */
    isRedeemable(){

        // has it been disabled?
        if (this.valid() === false){
            return false;
        }

        // this coupon has been redeemed the maximum amount of times
        if (this.maxRedemptionsIsSet() && this.timesRedeemed() >= this.maxRedemptions() ) {
            return false;
        }

        // is it before the coupon is valid?
        if (this.startDate() !== null && new Date() < this.startDate()) {
            return false;
        }

        // is it after the coupon is valid?
        if (this.endDate() !== null && new Date() > this.endDate()) {
            return false;
        }

        return true;
    }
    timesRedeemed() {
        throw new Error("Method not implemented.");
    }

    amountForDisplay(){
        if (this.type() === 'fixed') {
            return (this.amount() / 100).toFixed(2);
        }

        return this.amount();
    }

    prefixSymbol(){
        if (this.type() === 'fixed'){
            return '-$';
        }

        return '%';
    }

    postfixSymbol(){
        if (this.type() === 'fixed') {
            return '';
        }

        return ' off';
    }

    label(){
        return `${this.code()} (${this.prefixSymbol()}${this.amountForDisplay()}${this.postfixSymbol()})`
    }

    toJSON()
    {
        return this.$originalData;
    }
}

export { Coupon }