export function toHoursAndMinutes(totalMinutes) {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function validateDateString(datestring){

  const arr = datestring.split("-");

  let year = arr[0];
  let month = arr[1];
  let day = arr[2];

  if (year.length !== 4) {
    throw new Error("The year must be 4 digits long.");
  }
  if (month.length !== 2) {
    throw new Error("The month must be 2 digits long.");
  }
  if (day.length !== 2) {
    throw new Error("The day must be 2 digits long.");
  }
}

function validateTimeString(timestring){
  let arr = timestring.split(":");
  if (arr[0].length !== 2) {
    throw new Error("The hours must be 2 digits long.");
  }

  if (arr[1].length !== 2) {
    throw new Error("The minutes must be 2 digits long.");
  }

  if (arr[2].length !== 2) {
    throw new Error("The seconds must be 2 digits long.");
  }
}

export function dateStringToDate(datestring) {
  // throw an error if the  datestring is not YYYYY-MM-DD format

  validateDateString(datestring);

  const arr = datestring.split("-");
  const year = parseInt(arr[0]);
  const month = parseInt(arr[1]) - 1;
  const day = parseInt(arr[2]);

  const date = new Date(year, month, day);

  return date;
}

export function dateToYMD(date) {
  const year = date.getFullYear();
  const month = padTo2Digits(date.getMonth() + 1);
  const day = padTo2Digits(date.getDate());

  return `${year}-${month}-${day}`;
}

export function timeStringToDate(timestring){

  // throw an error if the  datestring is not HH:mm:ss format
  validateTimeString(timestring);
  
  const arr = timestring.split(":");
  const hours = parseInt(arr[0]);
  const minutes = parseInt(arr[1]);
  const seconds = parseInt(arr[2]);
  
  const date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    hours,
    minutes,
    seconds
  );

  return date;
}

export function dateTimeStringToDate(datetimestring){
  // throw an error if the  datestring is not YYYYY-MM-DD HH:mm:ss format

  const arr1 = datetimestring.split(" ");
  if (arr1.length !== 2) {
	  throw new Error("Datetime string must be format YYYY-MM-DD HH:mm:ss. It was " + datetimestring)
  }

  validateDateString(arr1[0]);

  const datestring = arr1[0].split("-");
  if (datestring.length !== 3) {
    throw new Error("Datetime string must be format YYYY-MM-DD HH:mm:ss")
  }
  const year = parseInt(datestring[0]);
  const month = parseInt(datestring[1]) - 1;
  const day = parseInt(datestring[2]);

  const timestring = arr1[1];

  validateTimeString(arr1[1]);
  
  const arr = timestring.split(":");
  if (arr.length !== 3) {
    throw new Error("Datetime string must be format YYYY-MM-DD HH:mm:ss")
  }
  const hours = parseInt(arr[0]);
  const minutes = parseInt(arr[1]);
  const seconds = parseInt(arr[2]);

  const date = new Date(
    year,
    month,
    day,
    hours,
    minutes,
    seconds
  );

  return date;
}

export function getDurationInMinutes(appointment){
    const dt1 = dateTimeStringToDate(appointment.start);
    const dt2 = dateTimeStringToDate(appointment.end);
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
}

