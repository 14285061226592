export default {
    'submitting' : false,
    'busy' : false,
    'submitText' : 'Submit Order',
    'student': {
        'firstname' : null,
        'lastname' : null,
        'email' : null,
        'phone' : null,
        'dob' : null,
        'driving_experience' : null,
        'access_token' : null
    },
    'customer_notes' : null,
    'guardian' : {
        'firstname' : null,
        'lastname' : null,
        'email' : null,
        'phone' : null
    },
    'permit': {
        'permit_number' : null,
        'issue_date' : null,
        'expiration_date' : null
    },
    'card' : {
        'payment_amount' : null,
        'name_on_card' : null,
        'number' : null,
        'expiration_month' : null,
        'expiration_year' : null,
        'cvc' : null
    },
    'billing_address': {
        'street' : null,
        'city' : null,
        'state' : null,
        'zip' : null,
        'country' : 'US',
    },
    'home_address': {
        'street' : null,
        'city' : null,
        'state' : null,
        'zip' : null
    },
    'terms_of_service' : null
}