
/**
 * @typedef DSConfig
 * @property {string} surcharge_detail
 * @property {string} gateway
 * @property {string} shopUrl
 * @property {boolean} surcharge_billed_separately
 * @property {number} surcharge_percentage
 * @property {boolean} cache_compatibility
 * @property {boolean} allows_scheduling
 * @property {boolean} allow_individual_class_scheduling
 * @property {{*}}
 */
export const rules = {
    surcharge_detail: "",
    gateway: "",
    shopUrl: "",
    surcharge_billed_separately: false,
    surcharge_percentage: 0,
    cache_compatibility: false,
    allows_scheduling: false,
    allow_individual_class_scheduling: false,
    gateway_config: {}
}

class ShopRules {

    /**
     * 
     * @param {DSConfig} data 
     */
    constructor(data = {}) {
        const { cache_compatibility, surcharge_percentage, surcharge_billed_separately, shopUrl, surcharge_detail, allow_individual_class_scheduling, allows_scheduling, gateway, gateway_config } = data;
        this.$surcharge_detail = surcharge_detail || '';
        this.$gateway = gateway || "None";
        this.$shopUrl = shopUrl || "";
        this.$surcharge_billed_separately = surcharge_billed_separately || false;
        this.$surcharge_percentage = parseInt(surcharge_percentage) || 0;
        this.$cache_compatibility = cache_compatibility || false;
        this.$allows_scheduling = allows_scheduling || false;
        this.$allow_individual_class_scheduling = allow_individual_class_scheduling || false;
        this.$cache_compatibility = cache_compatibility || false;
        this.$gateway_config = gateway_config;
    }

    shopUrl() {
        return this.$shopUrl;
    }

    surchargePercentage() {
        return this.$surcharge_percentage;
    }

    surchargeDetail() {
        return this.$surcharge_detail;
    }

    gateway() {
        return this.$gateway;
    }

    gatewayConfig() {
        return this.$gateway_config;
    }

    /**
     * 
     *
     * @returns {boolean}
     */
    allowScheduling() {
        return this.$allows_scheduling;
    }

    /**
     * 
     *
     * @returns {boolean}
     */
    allowIndividualClassScheduling() {
        return this.$allow_individual_class_scheduling;
    }

    /**
     * Whether or not the surcharge is billed separately
     *
     * @returns {boolean}
     */
    surchargeBilledSeparately() {
        return this.$surcharge_billed_separately;
    }

    /**
     *
     * @returns {number}
     */
    surchargeAmount() {
        return this.$surcharge_percentage;
    }

    /**
     * Whether or not the cache compatibility mode is enabled 
     *
     * @returns {boolean}
     */
    cacheCompatibilityEnabled() {
        return this.$cache_compatibility;
    }

}

export { ShopRules }
