import {inDollars} from './Teller'


class Product {
    constructor(data) {
        this.$originalData = Object.assign({}, data);
        const {id, name, description, price, sku, created_at, updated_at, purchase_instructions, sessiontypes, coursetypes, allow_partial_payment, can_schedule_lessons_during_checkout, deposit_required, deposit_type,
            deposit_amount, minimum_payment_amount} = data;
        this.$id = id;
        this.$name = name;
        this.$description = description;
        this.$price = price;
        this.$sku = sku;
        this.$created_at = created_at;
        this.$updated_at = updated_at;
        this.$session_types = sessiontypes || [];
        this.$course_types = coursetypes || [];
        this.$purchase_instructions = purchase_instructions;
        this.$services = [];

        this.$allow_partial_payment = allow_partial_payment || false;
        this.$can_schedule_lessons_during_checkout = can_schedule_lessons_during_checkout || false;
        this.$deposit_required = deposit_required || null;
        this.$deposit_type = deposit_type || null;
        this.$deposit_amount = deposit_amount || null;
        this.$minimum_payment_amount = minimum_payment_amount || null;
    }

    id() {
        return this.$id;
    }

    description(){
        return this.$description;
    }

    sessiontypes(){
        return this.$session_types;
    }

    coursetypes(){
        return this.$course_types;
    }

    name() {
        return this.$name;
    }

    price() {
        return this.$price;
    }

    priceInDollars(){
        return inDollars(this.price());
    }

    sku() {
        return this.$sku;
    }

    created_at() {
        return this.$created_at;
    }

    updated_at() {
        return this.$updated_at;
    }

    purchaseInstructions() {
        return this.$purchase_instructions;
    }

    services()
    {
        return this.$services;
    }

    setServices(services = [])
    {
        this.$services = services;
    }

    allowsPayLater()
    {
        return this.$allow_partial_payment
    }

    allowsDriveScheduling()
    {
        return this.$can_schedule_lessons_during_checkout;
    }

    requiresDeposit()
    {
        return this.$deposit_required;
    }

    depositType()
    {
        return this.$deposit_type;
    }

    depositAmount()
    {
        return this.$deposit_amount;
    }

    minimumPaymentAmount()
    {
        return this.$minimum_payment_amount;
    }


    // calculateMinimumDepositRequired(amount:number)
    calculateMinimumDepositRequired(amount)
    {
        if (this.allowsPayLater() === false) {
            return amount;
        }

        if (this.requiresDeposit() === false) {
            return 0;
        }

        if (this.depositType() === 'fixed') {
            amount = this.depositAmount();
        }
        if (this.depositType() === 'percentage') {
            amount = Math.round(amount * (this.depositAmount() / 100));
        }

        return amount;
    }

    toJSON()
    {
        return this.$originalData;
    }

}

export { Product }
