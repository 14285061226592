export const Months = [
    {'name' : '1', 'value' : "01"},
    {'name' : '2', 'value' : "02"},
    {'name' : '3', 'value' : "03"},
    {'name' : '4', 'value' : "04"},
    {'name' : '5', 'value' : "05"},
    {'name' : '6', 'value' : "06"},
    {'name' : '7', 'value' : "07"},
    {'name' : '8', 'value' : "08"},
    {'name' : '9', 'value' : "09"},
    {'name' : '10', 'value' : "10"},
    {'name' : '11', 'value' : "11"},
    {'name' : '12', 'value' : "12"}
]

function range(start, end) {
    var ans = [];
    for (let i = start; i <= end; i++) {
        ans.push(i);
    }
    return ans;
}

//this pulls in the current year plus 15 years in the future for cc forms
const min = new Date().getFullYear();
const max = min + 15;
const yearRange = range(min, max + 1);
export const Years = yearRange.map((year : number) : {name:string, value:string} => {
    let val = year.toString();
    if (year < 10) {
        val = "0" + year.toString();
    } else {
        val = val.toString();
    }
    return { name: year.toString(), value: val }
})
