function validateDateString(datestring) {

    const arr = datestring.split("/");

    let year = arr[2];
    let month = arr[0];
    let day = arr[1];

    if (year.length !== 4) {
        throw new Error("The year must be 4 digits long.");
    }
    if (month.length !== 2) {
        throw new Error("The month must be 2 digits long.");
    }
    if (day.length !== 2) {
        throw new Error("The day must be 2 digits long.");
    }
}

function dateStringToDate(datestring) {
    // throw an error if the  datestring is not YYYYY-MM-DD format

    validateDateString(datestring);

    const arr = datestring.split("/");
    const year = parseInt(arr[2]);
    const month = parseInt(arr[0]) - 1;
    const day = parseInt(arr[1]);


    const date = new Date(year, month, day);

    return date;
}

/**
 * Calculate someones age based on their birthday
 *
 * @param birthday
 */
// function AgeVerify(birthday:string):number
function AgeVerify(birthday) {
    try {
        var date1 = new dateStringToDate(birthday);
        var date2 = new Date();

        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        //TODO: Implement this in TS with tests
        if (Difference_In_Days / 365 < 0) {
            return 0;
        }
        return Difference_In_Days / 365;
    } catch {
        return 0; 
    }
}

export { AgeVerify }
